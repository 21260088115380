<template>
    <div>
        <NavBreadcrumb></NavBreadcrumb>
        <div class="panel-style">
            <el-form :inline="true" :model="searchDto" size="mini">
                <el-form-item label="产品型号">
                    <el-select style="width: 180px;" v-model="searchDto.productModelId" clearable placeholder="全部">
                        <el-option v-for="item in productModelOptions" :key="item.id" :label="item.name" :value="item.id"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="事件分类">
                    <el-select style="width: 90px;" v-model="searchDto.alarmClassify" clearable placeholder="全部">
                        <el-option v-for="item in alarmClassifyOptions" :key="item.value" :label="item.name" :value="item.value"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备SN号">
                    <el-input style="width: 220px;" v-model="searchDto.deviceSn" clearable placeholder="请输入设备SN号"/>
                </el-form-item>
                <el-form-item label="事件名称">
                    <el-input style="width: 170px;" v-model="searchDto.alarmName" clearable placeholder="请输入事件名称"/>
                </el-form-item>
                <el-form-item label="上报时间">
                    <el-select class="search-timeZone" v-model="searchDto.timeZone">
                        <el-option v-for="(item, index) in timeZoneOption" :key="index + item.label" :label="item.label" :value="item.value"/>
                    </el-select>
                    <el-date-picker class="search-date-picker" v-model="reportTimeRange" type="daterange" value-format="yyyy-MM-dd" :picker-options="pickerOptions"
                                    start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleQueryClickEvent()">查询</el-button>
                </el-form-item>
            </el-form>
            <div>
                <el-table border stripe size="small" :data="tableData" :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                          max-height="550px" v-loading="listLoading">
                    <el-table-column prop="productModelName" label="产品型号" align="center" width="180"/>
                    <el-table-column prop="deviceSn" label="设备SN号" width="220"/>
                    <el-table-column prop="alarmName" label="事件名称" />
                    <el-table-column prop="alarmClassify" label="事件分类" align="center" width="100" :formatter="eventClassifyFormatter"/>
                    <el-table-column prop="alarmLevel" label="事件等级" align="center" width="80">
                        <template v-slot="scope">
                            <span v-if="scope.row.alarmLevel === '1'" style="color:#F56C6C">故障</span>
                            <span v-else-if="scope.row.alarmLevel === '2'" style="color:#E6A23C">告警</span>
                            <span v-else-if="scope.row.alarmLevel === '3'" style="color:#409EFF">通知</span>
                            <span v-else>--</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reportTime" label="上报时间" align="center" width="140"/>
                </el-table>
            </div>
            <div class="pagination">
                <el-pagination
                    background
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tableTotal"
                    :current-page.sync="searchDto.pageNum"
                    :page-sizes="[10, 50, 100]"
                    :page-size="searchDto.pageSize"
                    @size-change="handleSizeChangeEvent"
                    @current-change="handleCurrentChangeEvent"/>
            </div>
        </div>
    </div>
</template>

<script>
    import message from '@/utils/message';
    import global from '@/components/Global.vue';
    import NavBreadcrumb from '@/components/NavBreadcrumb.vue';
    import { queryProductModeList, queryEventClassifyList, queryEventRecordList } from '@/api/esaio/event/esaioEventRecordApi';
    export default {

        name: 'AlarmRecordList',
        components: {NavBreadcrumb},
        data() {
            return {

                searchDto: {

                    productModelId: '',
                    alarmClassify: '',
                    deviceSn: '',
                    alarmName: '',
                    startDate: '',
                    endDate: '',
                    pageNum: 1,
                    pageSize: 10,
                    timeZone: 8
                },
                productModelOptions: [],
                alarmClassifyOptions: [],
                listLoading: false,
                tableData: [],
                tableTotal: 0,
                reportTimeRange: [],
                pickerOptions: {

                    disabledDate(time) {

                        return time.getTime() > Date.now();
                    }
                },
                timeZoneOption:[
                    { label: 'UTC+14', value: 14},
                    { label: 'UTC+13', value: 13},
                    { label: 'UTC+12', value: 12},
                    { label: 'UTC+11', value: 11},
                    { label: 'UTC+10', value: 10},
                    { label: 'UTC+9', value: 9},
                    { label: 'UTC+8', value: 8},
                    { label: 'UTC+7', value: 7},
                    { label: 'UTC+6', value: 6},
                    { label: 'UTC+5', value: 5},
                    { label: 'UTC+4', value: 4},
                    { label: 'UTC+3', value: 3},
                    { label: 'UTC+2', value: 2},
                    { label: 'UTC+1', value: 1},
                    { label: 'UTC', value: 0},
                    { label: 'UTC-1', value: -1},
                    { label: 'UTC-2', value: -2},
                    { label: 'UTC-3', value: -3},
                    { label: 'UTC-4', value: -4},
                    { label: 'UTC-5', value: -5},
                    { label: 'UTC-6', value: -6},
                    { label: 'UTC-7', value: -7},
                    { label: 'UTC-8', value: -8},
                    { label: 'UTC-9', value: -9},
                    { label: 'UTC-10', value: -10},
                    { label: 'UTC-11', value: -11},
                    { label: 'UTC-12', value: -12}
                ]
            }
        },
        methods: {

            handleCurrentChangeEvent(val) {

                this.searchDto.pageNum = val;
                this.queryEventRecordList();
            },
            handleSizeChangeEvent(val) {

                this.searchDto.pageSize = val;
                this.queryEventRecordList();
            },
            queryProductModeList() {

                queryProductModeList().then((res) => {

                    if (res.code === global.response_status_success_obj) {

                        this.productModelOptions = res.data;
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
            queryEventClassifyList() {

                queryEventClassifyList().then((res) => {

                    if (res.code === global.response_status_success_obj) {

                        this.alarmClassifyOptions = res.data;
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
            handleQueryClickEvent() {

                this.searchDto.pageNum = 1;
                this.queryEventRecordList();
            },
            queryEventRecordList() {

                this.listLoading = true;
                if (this.reportTimeRange && this.reportTimeRange.length > 1) {

                    this.searchDto.startDate = this.reportTimeRange[0];
                    this.searchDto.endDate = this.reportTimeRange[1];
                } else {

                    this.searchDto.startDate = null;
                    this.searchDto.endDate = null;
                }
                queryEventRecordList(this.searchDto).then(res => {

                    this.listLoading = false;
                    if (res.code === global.response_status_success_obj) {

                        this.tableTotal = res.data.total;
                        this.tableData = res.data.result;
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                    this.listLoading = false;
                })
            },
            eventClassifyFormatter(row, column, cellValue) {

                let eventClassify = this.alarmClassifyOptions.find(item => {

                    return item.value === cellValue;
                });
                if (eventClassify) {

                    return eventClassify.name;
                } else {

                    return '-';
                }
            }
        },
        mounted() {

            this.queryEventClassifyList();
            this.queryProductModeList();
            this.queryEventRecordList()
        }
    }
</script>

<style lang="scss" scoped>
.pagination {

    margin-top: 15px;
    text-align: right;
}
.search-timeZone {

    width: 95px;
}
.search-date-picker {

    width: 250px;
    margin-left: 5px;
}
</style>